import React, {useContext} from "react"
import emoji from "react-easy-emoji"
import "./StartupProjects.scss"
import {Fade} from "react-reveal"
import StyleContext from "../../contexts/StyleContext"
import Button from "../../components/button/Button"
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill"

export default function StartupProject() {
  const projects = [
    {
      image: require("../../assets/images/lanchatapp.png"),
      projectName: "LAN Chat Application",
      projectDesc: "Developed a JAVA based client - server chat application similar to WHATSAPP DESKTOP.",
      descBullets: [
        emoji("⚡ Users have the option to register and login. They can view other online users."),
        emoji("⚡ Programmed private chat feature using TCP/IP protocol."),
        emoji("⚡ Developed an intuitive UI for the desktop application."),
        emoji("⚡ Created backup functionality where messages are stored in JSON files both on server as well as client side."),
        emoji("⚡ Provided feature that allows to message offline users."),
      ],
      technologiesUsed : [
        {
          skillName: "Java",
          imageLink: require("../../assets/images/java.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "JSON",
          imageLink: require("../../assets/images/json.webp")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/lanchatapplication"
        }
      ]
    },
    {
      image: require("../../assets/images/automaticqpg.png"),
      projectName: "Automatic Question Paper Generator",
      projectDesc: "Developed a desktop application that creates question paper based on difficulty and marks provided.",
      descBullets: [
        emoji("⚡ CRUD operations are provided on subjects, chapters and questions."),
        emoji("⚡ Integrated probability model that selects questions that are not repeteadly selected."),
        emoji("⚡ Developed user-friendly UI to create question papers."),
        emoji("⚡ Designed the dynamic question paper using iTextPDF library."),
        emoji("⚡ Developed the logic using database and probability."),
      ],
      technologiesUsed : [
        {
          skillName: "Java",
          imageLink: require("../../assets/images/java.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "iTextPDF",
          imageLink: require("../../assets/images/itextpdf.png")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/automatic-question-paper-generator"
        }
      ]
    },
    {
      image: require("../../assets/images/automatictc.jpeg"),
      projectName: "Automatic Table Creator",
      projectDesc: "Developed an application which creates tables in database as per JSON file provided.",
      descBullets: [
        emoji("⚡ Designed the JSON file format which the application understands and creates tables in database."),
        emoji("⚡ Created parser which parses the JSON file and performs further work."),
        emoji("⚡ Developed this application similar to migrations which Laravel provides."),
      ],
      technologiesUsed : [
        {
          skillName: "Java",
          imageLink: require("../../assets/images/java.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "JSON",
          imageLink: require("../../assets/images/json.webp")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/automatic-table-creator-java"
        }
      ]
    },
    {
      image: require("../../assets/images/blogpenit.png"),
      projectName: "Blog-Pen-IT",
      projectDesc: "Developed a web application that allows users to post their blogs and create categories and tags.",
      descBullets: [
        emoji("⚡ Developed Controllers, Models and Views created as per MVC architecture."),
        emoji("⚡ Included soft-deletes functionality in the application for the account holder."),
        emoji("⚡ Integrated custom Middlewares for authorization and other security features."),
      ],
      technologiesUsed : [
        {
          skillName: "PHP",
          imageLink: require("../../assets/images/php.png")
        },
        {
          skillName: "Laravel",
          imageLink: require("../../assets/images/laravel.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "HTML 5",
          imageLink: require("../../assets/images/html5.png")
        },
        {
          skillName: "CSS 3",
          imageLink: require("../../assets/images/css3.png")
        },
        {
          skillName: "JavaScript",
          imageLink: require("../../assets/images/js.png")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/blog_pen_it"
        }
      ]
    },
    {
      image: require("../../assets/images/trello.webp"),
      projectName: "Trello",
      projectDesc: "Developed a clone web application of Trello using Laravel.",
      descBullets: [
        emoji("⚡ Developed the application that enables users to create workspaces, boards and lists."),
        emoji("⚡ Built real time system that allows the users to manage work using Laravel Broadcasting."),
        emoji("⚡ Created custom Middlewares, Policies, Scopes and soft-deletes functionality."),
        emoji("⚡ Used Pusher and Echo for handing events and listeners."),
      ],
      technologiesUsed : [
        {
          skillName: "PHP",
          imageLink: require("../../assets/images/php.png")
        },
        {
          skillName: "Laravel",
          imageLink: require("../../assets/images/laravel.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "HTML 5",
          imageLink: require("../../assets/images/html5.png")
        },
        {
          skillName: "CSS 3",
          imageLink: require("../../assets/images/css3.png")
        },
        {
          skillName: "JavaScript",
          imageLink: require("../../assets/images/js.png")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/trello"
        }
      ]
    },
    {
      image: require("../../assets/images/ecommerce.png"),
      projectName: "E-Commerce RestAPI",
      projectDesc: "Developed Rest API using Ecommerce Project.",
      descBullets: [
        emoji("⚡ Designed Rest API with all endpoints secured allowing only authorized user to access the API."),
        emoji("⚡ Implemented and Tested the API using Postman tool."),
        emoji("⚡ Created custom Middlewares, Policies, Transformers, Scopes and soft-deletes functionality."),
        emoji("⚡ Implemented throttling and other security features to prevent degradation of the system.")
      ],
      technologiesUsed : [
        {
          skillName: "PHP",
          imageLink: require("../../assets/images/php.png")
        },
        {
          skillName: "Laravel",
          imageLink: require("../../assets/images/laravel.png")
        },
        {
          skillName: "MySQL",
          imageLink: require("../../assets/images/mysql.png")
        },
        {
          skillName: "REST Api",
          imageLink: require("../../assets/images/rest.png")
        },
        {
          skillName: "Postman",
          imageLink: require("../../assets/images/postman.png")
        }
      ],
      footerLink: [
        {
          name: "GitLab Link",
          url: "https://gitlab.com/Keval_Sanghvi/ecommerce-rest"
        }
      ]
    },
  ]

  function openUrlInNewTab(url) {
    if (!url) {
      return
    }
    var win = window.open(url, "_blank")
    win.focus()
  }

  const {isDark} = useContext(StyleContext)
  
  return (
    <Fade bottom duration={2000} distance="50px" >
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">{"Projects 📚"}</h1>
          <div className="projects-container">
            {projects.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.image ? (
                    <div className="project-image">
                      <img
                        src={project.image}
                        alt={project.projectName}
                        className="card-image"
                      ></img>
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.projectDesc}
                    </p>
                    {
                      project.descBullets.map((item, i) => (
                        <p className="feature_item" key={i}>
                          {item}
                        </p>
                      ))
                    }
                    <SoftwareSkill list={project.technologiesUsed} />
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <span
                              key={i}
                              className={
                                isDark ? "dark-mode project-tag" : "project-tag"
                              }
                              onClick={() => openUrlInNewTab(link.url)}
                            >
                              {link.name}
                            </span>
                          )
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="moreProjectsButton">
            <Button 
              text={"GitHub"}
              href={"https://github.com/keval2605"}
              newTab={true}
            />
            <Button 
              text={"GitLab"}
              href={"https://gitlab.com/Keval_Sanghvi"}
              newTab={true}
            />
          </div>
        </div>
      </div>
    </Fade>
  )
}