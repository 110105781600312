import React from "react"
import "./Education.scss"
import EducationCard from "../../components/educationCard/EducationCard"
import emoji from "react-easy-emoji"

const educationInfo = {
  schools: [
    {
      schoolName: "K J Somaiya College of Engineering",
      logo: require("../../assets/images/somaiya.png"),
      subHeader: "Bachelor of Technology in Computer Engineering",
      duration: "August 2019 - May 2023",
      desc: "",
      descBullets: [
        emoji("⚡ CGPA : 8.8 / 10"),
        emoji("⚡ Won HackerBash Hackathon organized by Computer Society of India (CSI)"),
        emoji("⚡ Developed a website for the annual cultural Fest, Symphony (2021)"),
        emoji("⚡ Selected in College Kho-Kho Team"),
        emoji("⚡ Studied computer science fundamentals like Data Structures, Algorithms, Operating Systems, etc"),
      ]
    },
    {
      schoolName: "K J Somaiya College of Science and Commerce",
      logo: require("../../assets/images/somaiya.png"),
      subHeader: "Higher Secondary Degree",
      duration: "2017 - 2019",
      desc: "",
      descBullets: [
        emoji("⚡ Scored 85.23 % in the HSC exam"),
        emoji("⚡ Received 98.85 percentile in MHT-CET"),
        emoji("⚡ Received 90.66 percentile in JEE"),
        emoji("⚡ Studied subjects like C++, Visual Basics, Microprocessors, etc"),
      ]
    },
    {
      schoolName: "Sheth Karamshi Kanji English School",
      logo: require("../../assets/images/skkes.png"),
      subHeader: "Matriculate Degree",
      duration: "2005 - 2017",
      desc: "",
      descBullets: [
        emoji("⚡ Scored 89.2 % in the SSC exam"),
        emoji("⚡ Won Kho-Kho State Championship"),
      ]
    }
  ]
}

export default function Education() {
  return (
    <div className="education-section" id="education">
      <h1 className="education-heading">{"Education 🎓"}</h1>
      <div className="education-card-container">
        {educationInfo.schools.map((school, index) => (
          <EducationCard key={index} school={school} />
        ))}
      </div>
    </div>
  )
}