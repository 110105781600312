import React from "react"
import { Fade } from "react-reveal"
import "./ExperienceCard.scss"
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill"
import { Flip } from "react-reveal"

export default function ExperienceCard({cardInfo, isDark}) {
  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => (
        <p key={i} className="experience-description">
          {item}
        </p>
        ))
      : null
  }

  return (
    <div>
      <Fade>
        <div className={isDark ? "experience-card experience-whiteborder" : "experience-card experience-blackborder"}>
          <div className="experience-card-left">
            <Flip left>
              <img
                crossOrigin={"anonymous"}
                className="experience-roundedimg"
                src={cardInfo.companylogo}
                alt={cardInfo.company}
              />
            </Flip>
          </div>
          <div className="experience-card-right">
            <div className="experience-title-name-duration">
              <h3 className="experience-text-company">{cardInfo.company}</h3>
              <small
                className={`${
                  isDark ? "dark-mode" : ""
                } experience-text-duration`}
              >
                {cardInfo.date}
              </small>
            </div>
            <div>
              <h4 className="experience-text-desc">{cardInfo.role}</h4>
              <h5 className={isDark ? "dark-mode experience-text-subHeader" : "experience-text-subHeader"}>
                {cardInfo.desc}
              </h5>
              <div className="experience-text">
                <GetDescBullets descBullets={cardInfo.descBullets} />
              </div>
            </div>
            <SoftwareSkill 
              list={cardInfo.technologies}
            />
          </div>
        </div>
      </Fade>
    </div>
  )
}