import React, {useContext} from "react"
import "./Contact.scss"
import SocialMedia from "../../components/socialMedia/SocialMedia"
import {Fade} from "react-reveal"
import StyleContext from "../../contexts/StyleContext"
import emoji from "react-easy-emoji"
import working from "../../assets/images/contactMailDark.svg"

export default function Contact() {
  const {isDark} = useContext(StyleContext)

  const contactInfo = {
    title: emoji("Contact Me ☎️"),
    subtitle: "Discuss a project or just want to have a chat? My inbox is open for all!",
    number: "+91-9768387175",
    email_address: "kevalssanghvi100@gmail.com"
  }

  return (
    <Fade bottom duration={2000} distance="50px" >
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{contactInfo.title}</h1>
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {contactInfo.subtitle}
            </p>
            <div className={isDark ? "dark-mode contact-text-div" : "contact-text-div"}>
              {contactInfo.number && (
                <>
                  <a
                    className="contact-detail"
                    href={"tel:" + contactInfo.number}
                  >
                    {contactInfo.number}
                  </a>
                  <br />
                  <br />
                </>
              )}
              <a
                className="contact-detail-email"
                href={"mailto:" + contactInfo.email_address}
              >
                {contactInfo.email_address}
              </a>
              <br />
              <br />
              <SocialMedia />
            </div>
          </div>
          <div className="contact-image-div">
            <img
              alt="Man working"
              src={working}
            />
          </div>
        </div>
      </div>
    </Fade>
  )
}