import React, {createRef, useContext} from "react"
import {Slide} from "react-reveal"
import "./EducationCard.scss"
import StyleContext from "../../contexts/StyleContext"
import Flip from 'react-reveal/Flip'

export default function EducationCard({school}) {
  const imgRef = createRef()

  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => (
        <p key={i}>{item}</p>
        ))
      : null
  }
  const {isDark} = useContext(StyleContext)

  return (
    <div>
        <div className="education-card">
          <div className="education-card-left">
            <Flip left>
              <img
                crossOrigin={"anonymous"}
                ref={imgRef}
                className="education-roundedimg"
                src={school.logo}
                alt={school.schoolName}
              />
            </Flip>
          </div>
          <div className="education-card-right">
            <h5 className="education-text-school">{school.schoolName}</h5>
            <div className="education-text-details">
              <h5 className={isDark ? "dark-mode education-text-subHeader" : "education-text-subHeader"}>
                {school.subHeader}
              </h5>
              <p
                className={`${
                  isDark ? "dark-mode" : ""
                } education-text-duration`}
              >
                {school.duration}
              </p>
              <p className="education-text-desc">{school.desc}</p>
              <div className="education-text">
                <GetDescBullets descBullets={school.descBullets} />
              </div>
            </div>
          </div>
        </div>
      <Slide left duration={2000}>
        <div className="education-card-border"></div>
      </Slide>
    </div>
  )
}