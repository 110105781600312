import React from "react"
import "./SoftwareSkill.scss"

export default function SoftwareSkill(props) {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {props.list.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
              >
                <img height={50} width={50} src={skills.imageLink} alt={skills.skillName}/>
                <p>{skills.skillName}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}