import React from "react"
import "./Progress.scss"
import {Fade} from "react-reveal"
import skills from "../../assets/images/skill.svg"

export default function StackProgress() {
  const experience = [
    {
      Stack: "Front-End Development",
      progressPercentage: "85%"
    },
    {
      Stack: "Back-End Development",
      progressPercentage: "85%"
    },
    {
      Stack: "Desktop Applications", 
      progressPercentage: "75%"
    },
    {
      Stack: "Mobile Applications", 
      progressPercentage: "50%"
    },
  ]

  const programmingLanguageOne = [
    {
      Stack: "Java", 
      progressPercentage: "80%"
    },
    {
      Stack: "Python",
      progressPercentage: "75%"
    },
    {
      Stack: "C / C++",
      progressPercentage: "80%"
    },
    {
      Stack: "JavaScript", 
      progressPercentage: "80%"
    },
    {
      Stack: "TypeScript", 
      progressPercentage: "75%"
    },
    {
      Stack: "PHP", 
      progressPercentage: "80%"
    }
  ]

  const programmingLanguageTwo = [
    {
      Stack: "Laravel",
      progressPercentage: "80%"
    },
    {
      Stack: "Django",
      progressPercentage: "75%"
    },
    {
      Stack: "React JS",
      progressPercentage: "70%"
    },
    {
      Stack: "Spring Boot",
      progressPercentage: "60%"
    },
    {
      Stack: "Vue JS",
      progressPercentage: "60%"
    },
    {
      Stack: "Next JS",
      progressPercentage: "55%"
    }
  ]

  return (
    <div>
      <Fade bottom duration={2000} distance="50px" >
        <div className="skills-container">
          <div className="skills-bar">
            <h1 className="skills-heading">Proficiency</h1>
            {experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage
              }
              return (
                <div key={i} className="skill">
                  <p>{exp.Stack}</p>
                  <div className="meter">
                    <span style={progressStyle}></span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="skills-image">
            <img
              alt="Skills"
              src={skills}
            />          
          </div>
        </div>
      </Fade>
      <div className="programmingLanguages">
        <div className="skills-bar-new">
          <h1 className="skills-heading">Languages</h1>
          {programmingLanguageOne.map((exp, i) => {
            const progressStyle = {
              width: exp.progressPercentage
            }
            return (
              <div key={i} className="skill">
                <p>{exp.Stack}</p>
                <div className="meter">
                  <span style={progressStyle}></span>
                </div>
              </div>
            )
          })}
        </div>
        <div className="skills-bar-new">
          <h1 className="skills-heading">Frameworks / Libraries</h1>
          {programmingLanguageTwo.map((exp, i) => {
            const progressStyle = {
              width: exp.progressPercentage
            }
            return (
              <div key={i} className="skill">
                <p>{exp.Stack}</p>
                <div className="meter">
                  <span style={progressStyle}></span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
    )
}
