import React, {useContext} from "react"
import "./Footer.scss"
import emoji from "react-easy-emoji"
import StyleContext from "../../contexts/StyleContext"

export default function Footer() {
  const {isDark} = useContext(StyleContext)
  return (
    <div className="footer-div">
      <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
        <a href="https://www.linkedin.com/in/keval-sanghvi" style={{textDecoration: "none"}}> 
          {emoji("Made with ❤️ by Keval Sanghvi")}
        </a>
      </p>
      <p className={isDark ? "dark-mode footer-text footer-small-text footer-dark-text" : "footer-text footer-small-text footer-light-text"}>
        Theme by DeveloperFolio
      </p>
    </div>
  )
}