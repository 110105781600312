import React from "react"
import Header from "../components/header/Header"
import Greeting from "./greeting/Greeting"
import Skills from "./skills/Skills"
import StackProgress from "./skillProgress/skillProgress"
import Education from "./education/Education"
import WorkExperience from "./workExperience/WorkExperience"
import StartupProject from "./StartupProjects/StartupProject"
import Achievement from "./achievement/Achievement"
import Footer from "../components/footer/Footer"
import Contact from "../containers/contact/Contact"
import Top from "./topbutton/Top"
import {StyleProvider} from "../contexts/StyleContext"
import {useLocalStorage} from "../hooks/useLocalStorage"
import "./Main.scss"

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)")
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches)

  const changeTheme = () => {
    setIsDark(!isDark)
  }

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        <Header />
        <Greeting />
        <Skills />
        <StackProgress />
        <Education />
        <WorkExperience />
        <StartupProject />
        <Achievement />
        <Contact />
        <Footer />
        <Top />
      </StyleProvider>
    </div>
  )
}

export default Main