import React, {useContext} from "react"
import "./WorkExperience.scss"
import ExperienceCard from "../../components/experienceCard/ExperienceCard"
import StyleContext from "../../contexts/StyleContext"
import emoji from "react-easy-emoji"
import django from "../../assets/images/django.svg"

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext)

  const workExperiences = {
    display: true,
    experience: [
      {
        role: "Developer",
        company: "Barclays",
        companylogo: require("../../assets/images/barclays.png"),
        date: "July 2023 – Present",
        desc: "Working as a Developer in Reference Data Services (Markets Pre Trade Department)",
        descBullets: [
          emoji("⚡ Developed a utility application that provides users with a grid format view of data from any API, offering advanced search and sorting capabilities, as well as the option to download data in CSV format."),
          emoji("⚡ Implemented rigorous testing methodologies, utilizing JUnit and Mockito to increase code coverage of an application from 4% to an impressive 72%."),
          emoji("⚡ Engineered a cache service wrapper for GridGain and Coherence, introducing a dynamic mechanism for efficient cache serialization and deserialization to streamline restoration processes."),
          emoji("⚡ Crafted a Docker image for the application, orchestrated its deployment on Kubernetes within a cloud-based APAAS environment, and administered configurations using the OC command line tool."),
          emoji("⚡ Developed an automated password rotation process using Bash scripts and Java, executing every 60 days to create keytabs, update OpenShift secrets, and update files and databases, guaranteeing seamless application functionality."),
        ],
        technologiesUsed : [
          {
            skillName: "Angular",
            imageLink: require("../../assets/images/angular.png")
          },
          {
            skillName: "React",
            imageLink: require("../../assets/images/react.png")
          },
          {
            skillName: "Java",
            imageLink: require("../../assets/images/java.png")
          },
          {
            skillName: "Spring",
            imageLink: require("../../assets/images/spring.webp")
          },
          {
            skillName: "Node JS",
            imageLink: require("../../assets/images/nodejs.png")
          },
          {
            skillName: "Bitbucket",
            imageLink: require("../../assets/images/bitbucket.png")
          },
          {
            skillName: "Jira",
            imageLink: require("../../assets/images/jira.png")
          },
          {
            skillName: "Confluence",
            imageLink: require("../../assets/images/confluence.webp")
          },
          {
            skillName: "SQL Server",
            imageLink: require("../../assets/images/sql-server.png")
          },
          {
            skillName: "JUnit",
            imageLink: require("../../assets/images/junit.png")
          },
          {
            skillName: "Mockito",
            imageLink: require("../../assets/images/mockito.png")
          },
          {
            skillName: "Hadoop",
            imageLink: require("../../assets/images/hadoop.png")
          },
          {
            skillName: "Swagger",
            imageLink: require("../../assets/images/swagger.png")
          },
          {
            skillName: "Jacoco",
            imageLink: require("../../assets/images/jacoco.png")
          },
          {
            skillName: "Sonarqube",
            imageLink: require("../../assets/images/sonarqube.png")
          },
        ]
      },
      {
        role: "Software Developer",
        company: "NxtBig Software Labs Pvt. Ltd.",
        companylogo: require("../../assets/images/nxtbig.png"),
        date: "March 2023 – June 2023",
        desc: "Worked as a Full Stack Software Developer",
        descBullets: [
          emoji("⚡ Explored headless e-commerce websites like Medusa.js, Saleor.io, Vendure, and Zoho books and provided Qualitative and Quantitative analysis."),
          emoji("⚡ Designed the database using Prisma ORM and implementing it with Supabase, enabling strong authentication and authorization."),
          emoji("⚡ Created engaging and interactive UI designs using Next.js for the applications and built REST APIs, assisting in CRUD operations."),
          emoji("⚡ Integrated the application with Zoho CRM, Zoho Books, and Zoho Desk by creating webhooks and triggers for various events to maintain the synchronization of user data among all four systems."),
          emoji("⚡ Curated form data based on ITR filing provided by the Government of India and converted it into interactive UI forms that assist the end user in filing the ITR based on answers selected in the previous form pages."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML 5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS 3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          },
          {
            skillName: "Typescript",
            imageLink: require("../../assets/images/typescript.png")
          },
          {
            skillName: "Next.js",
            imageLink: require("../../assets/images/nextjs.png")
          },
          {
            skillName: "Supabase",
            imageLink: require("../../assets/images/supabase.png")
          },
          {
            skillName: "Prisma ORM",
            imageLink: require("../../assets/images/prisma.png")
          },
        ]
      },
      {
        role: "Summer Intern",
        company: "Barclays",
        companylogo: require("../../assets/images/barclays.png"),
        date: "June 2022 – August 2022",
        desc: "Worked as a Test Automation Engineer",
        descBullets: [
          emoji("⚡ Was part of the Markets Pre Trade Department."),
          emoji("⚡ Tested hundreds of scenarios for the applications developed by the IT Web Portal Department."),
          emoji("⚡ Enhanced the testing procedure by performing automation testing as well as manual testing."),
          emoji("⚡ Utilized Cucumber.js and Cypress.io testing frameworks to facilitate behavior-driven development and ensure that the applications met the desired specifications and functionality."),
          emoji("⚡ Used Bitbucket as Git repository management tool for collaborating with other engineers."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML 5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS 3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          },
          {
            skillName: "Cypress.io",
            imageLink: require("../../assets/images/cypress.png")
          },
          {
            skillName: "Cucumber JS",
            imageLink: require("../../assets/images/cucumber.png")
          },
          {
            skillName: "Selenium",
            imageLink: require("../../assets/images/selenium.png")
          },
          {
            skillName: "Bitbucket",
            imageLink: require("../../assets/images/bitbucket.png")
          },
        ]
      },
      {
        role: "Technical Intern",
        company: "Intract Technologies",
        companylogo: require("../../assets/images/intract-technologies.png"),
        date: "February 2022 – April 2022",
        desc: "Worked as a Full Stack Developer",
        descBullets: [
          emoji("⚡ Collaborated with the team for developing new features for ERP, mobile and web application."),
          emoji("⚡ Designed and Developed SMS Dashboard system for the ERP application."),
          emoji("⚡ Converted Template Docx to PDF with appropriate data and then to base64 string for efficient solution."),
          emoji("⚡ Created forms and intuitive UI designs for different portals."),
          emoji("⚡ Worked on three different products belonging to the same umbrella and followed the Agile methodology with Scrum meetings."),
        ],
        technologiesUsed : [
          {
            skillName: "Vue JS",
            imageLink: require("../../assets/images/vuejs.png")
          },
          {
            skillName: "Vuetify",
            imageLink: require("../../assets/images/vuetify.png")
          },
          {
            skillName: "Python",
            imageLink: require("../../assets/images/python.png")
          },
          {
            skillName: "Django",
            imageLink: django
          },
          {
            skillName: "REST Api",
            imageLink: require("../../assets/images/rest.png")
          },
        ]
      },
      {
        role: "Web Development Intern",
        company: "K J Somaiya College of Engineering",
        companylogo: require("../../assets/images/somaiya.png"),
        date: "February 2021 – April 2021",
        desc: "Worked as a Front-End Developer",
        descBullets: [
          emoji("⚡ Developed and initiated the concepts for the theme of University's Cultural Festival Symphony 2021."),
          emoji("⚡ Collaborated with the crew while creating wireframes, mockups, and theme-appropriate colours and fonts."),
          emoji("⚡ Innovated creative strategies to guarantee a responsive website working well on all screen sizes."),
          emoji("⚡ Integrated user-friendly forms for participation in different events that would be live-streamed."),
          emoji("⚡ Utilized various JavaScript libraries to develop websites with animations, enhancing the user experience."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          }
        ]
      },
    ]
  }

  return (
    <div id="experience">
        <div className="experience-container" id="workExperience">
          <div>
            <h1 className="experience-heading">{"Work Experience 👔"}</h1>
            <div className="experience-cards-div">
              {workExperiences.experience.map((card, i) => {
                return (
                  <ExperienceCard
                    key={i}
                    isDark={isDark}
                    cardInfo={{
                      company: card.company,
                      desc: card.desc,
                      date: card.date, 
                      companylogo: card.companylogo,
                      role: card.role,
                      descBullets: card.descBullets,
                      technologies: card.technologiesUsed
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
    </div>
  )
}