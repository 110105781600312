import React, {useState, useContext} from "react"
import StyleContext from "../../contexts/StyleContext"
import "./ToggleSwitch.scss"

const ToggleSwitch = () => {
  const {isDark} = useContext(StyleContext)
  const [isChecked, setisChecked] = useState(isDark)
  const styleContext = useContext(StyleContext)

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isDark}
        onChange={() => {
          styleContext.changeTheme()
          setisChecked(!isChecked)
        }}
      />
      <span className="slider round"></span>
    </label>
  )
}

export default ToggleSwitch