import React, {useContext} from "react"
import "./Skills.scss"
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill"
import emoji from "react-easy-emoji"
import StyleContext from "../../contexts/StyleContext"
import django from "../../assets/images/django.svg"

export default function Skills() {
  const {isDark} = useContext(StyleContext)

  const softwareSkillsWebDev = [
    {
      skillName: "HTML 5",
      imageLink: require("../../assets/images/html5.png")
    },
    {
      skillName: "CSS 3",
      imageLink: require("../../assets/images/css3.png")
    },
    {
      skillName: "Bootstrap",
      imageLink: require("../../assets/images/bootstrap.png")
    },
    {
      skillName: "Java",
      imageLink: require("../../assets/images/java.png")
    },
    {
      skillName: "C",
      imageLink: require("../../assets/images/c.png")
    },
    {
      skillName: "C++",
      imageLink: require("../../assets/images/c++.png")
    },
    {
      skillName: "JavaScript",
      imageLink: require("../../assets/images/js.png")
    },
    {
      skillName: "jQuery",
      imageLink: require("../../assets/images/jquery.webp")
    },
    {
      skillName: "Webpack",
      imageLink: require("../../assets/images/webpack.png")
    },
    {
      skillName: "React JS",
      imageLink: require("../../assets/images/react.png")
    },
    {
      skillName: "Vue JS",
      imageLink: require("../../assets/images/vuejs.png")
    },
    {
      skillName: "Node JS",
      imageLink: require("../../assets/images/nodejs.png")
    },
    {
      skillName: "BitBucket",
      imageLink: require("../../assets/images/bitbucket.png")
    },
    {
      skillName: "Git",
      imageLink: require("../../assets/images/git.png")
    },
    {
      skillName: "Python",
      imageLink: require("../../assets/images/python.png")
    },
    {
      skillName: "Django",
      imageLink: django
    },
    {
      skillName: "MySQL",
      imageLink: require("../../assets/images/mysql.png")
    },
    {
      skillName: "PostgreSQL",
      imageLink: require("../../assets/images/postgresql.png")
    },
  ]
  
  const skillsWebDev = [
    emoji("⚡ Experienced in working with Java, React JS, Django, Laravel, Python and Node."),
    emoji("⚡ Gained practical knowledge by working on industry-level projects at Barclays, NxtBig Software Labs and Intract Technologies.")
  ]

  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <div className="skills-image-div">
          <img
            alt="Man Working"
            src={require("../../assets/images/whatido.gif")}
          />
        </div>
        <div className="skills-text-div">
          <h1 className={isDark ? "dark-mode skills-heading" : "skills-heading"}>
            {"What I Do?"}
          </h1>
          <p
            className={
              isDark
                ? "dark-mode subTitle skills-text-subtitle"
                : "subTitle skills-text-subtitle"
            }
          >
            {"Leveraging technology to solve complex real-world problems!"}
          </p>
          <SoftwareSkill list={softwareSkillsWebDev} />
          <div>
            {skillsWebDev.map((skills, i) => {
              return (
                <p
                  key={i}
                  className={
                    isDark
                      ? "dark-mode subTitle skills-text"
                      : "subTitle skills-text"
                  }
                >
                  {skills}
                </p>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}