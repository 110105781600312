import React, {useContext} from "react"
import "./Achievement.scss"
import AchievementCard from "../../components/achievementCard/AchievementCard"
import {Fade} from "react-reveal"
import StyleContext from "../../contexts/StyleContext"
import emoji from "react-easy-emoji"

export default function Achievement() {
  const {isDark} = useContext(StyleContext)

  const achievementSection = {
    title: emoji("Achievements and Certifications 🏆"),
    achievementsCards: [
      {
        title: "HackerBash Hackathon",
        courseBy: "Computer Society of India",
        subtitle: "Won first prize in 24-hour Hackathon as Team GrowMore. Built a geo-application using Python and GeoDjango that helps Indian citizens find the nearest COVID19 vaccination center allowing the user to book an appointment to that vaccine center.",
        image: require("../../assets/images/hackerbash.png"),
        footerLink: [
          {
            name: "Certificate Link",
            url: "https://drive.google.com/file/d/1g6WZIarb3wEjImOm8tDsQ22ZgGoQtCU1/view?usp=share_link"
          }
        ]
      },
      // {
      //   title: "Software Engineering Virtual Internship",
      //   courseBy: "JP Morgan Chase & Co.",
      //   subtitle: "Successfully completed the Virtual Internship and familiarized myself with JPMorgan Chase frameworks and applied my technical skills to a hypothetical request from the firm’s trading floor to analyze and visualize data in a new way.",
      //   image: require("../../assets/images/jpmc.jpg"),
      //   footerLink: [
      //     {
      //       name: "Certificate Link",
      //       url: "https://insidesherpa.s3.amazonaws.com/completion-certificates/JP%20Morgan/R5iK7HMxJGBgaSbvk_JPMorgan%20Chase_PYup3zasZGb9SRnxQ_completion_certificate.pdf"
      //     }
      //   ]
      // },
      // {
      //   title: "SYBGEN Python Boot Camp",
      //   courseBy: "SYBGEN",
      //   subtitle: "Successfully completed the python bootcamp including basic as well as advanced concepts of Python. With the help of this bootcamp, I learned fundamentals of python Programming which will in turn help for Data Science and Machine learning in future.",
      //   image: require("../../assets/images/sybgen.png"),
      //   footerLink: [
      //     {
      //       name: "Certificate Link",
      //       url: "https://drive.google.com/file/d/17f402X2OFyJ254zj6h0uc33jFxeowM-e/view"
      //     }
      //   ]
      // },
      {
        title: "30 Days of Google Cloud",
        courseBy: "Google",
        subtitle: "Successfully completed 12 courses within 30 days that belonged to Cloud Engineering Track and Data Science & Machine Learning Track. This course helped me gain practical knowledge on cloud services, virtual training of models and Big Data Analysis.",
        image: require("../../assets/images/google_cloud.png"),
        footerLink: [
          {
            name: "Certificate Link",
            url: "https://certificate.givemycertificate.com/c/59cb215b-1a4c-4972-b080-232ba9b2a43f"
          }
        ]
      }
    ]
  }

  return (
    <Fade bottom duration={2000} distance="50px" >
      <div className="main" id="achievements">
        <div className="achievement-main-div">
          <div className="achievement-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading achievement-heading"
                  : "heading achievement-heading"
              }
            >
              {achievementSection.title}
            </h1>
          </div>
          <div className="achievement-cards-div">
            {achievementSection.achievementsCards.map((card, i) => {
              return (
                <AchievementCard
                  key={i}
                  isDark={isDark}
                  cardInfo={{
                    title: card.title,
                    description: card.subtitle,
                    image: card.image,
                    footer: card.footerLink,
                    courseBy: card.courseBy
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Fade>
  )
}