import React from "react"
import "./SocialMedia.scss"

export default function socialMedia() {
  return (
    <div className="social-media-div">
      <a
        href="https://www.linkedin.com/in/keval-sanghvi"
        className="icon-button linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
        <span></span>
      </a>
      <a
        href="https://github.com/keval2605"
        className="icon-button github"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-github"></i>
        <span></span>
      </a>
      <a
        href="https://gitlab.com/Keval_Sanghvi"
        className="icon-button gitlab"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-gitlab"></i>
        <span></span>
      </a>
      <a
        href={"mailto:kevalssanghvi100@gmail.com"}
        className="icon-button google"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-envelope"></i>
        <span></span>
      </a>
      <a
        href="https://www.facebook.com/kevalssanghvi"
        className="icon-button facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook-f"></i>
        <span></span>
      </a>
      <a
        href="https://www.instagram.com/keval_sanghvi_"
        className="icon-button instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-instagram"></i>
        <span></span>
      </a>
    </div>
  )
}